import { Component, OnInit,ViewChild } from '@angular/core';

import {Inject, Injectable} from '@angular/core';
import {HealthService } from '../health.service';
import {HealthModel} from '../health.model';
import {interval, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { Http } from '@angular/http';
import { ActivatedRoute } from "@angular/router";
import { OktaAuthService } from '@okta/okta-angular';
import {Sort} from '@angular/material/sort';
@Component({
  selector: 'app-owner-service-health',
  templateUrl: './owner-service-health.component.html',
  styleUrls: ['./owner-service-health.component.css']
})
export class OwnerServiceHealthComponent implements OnInit {
  
  applicationStatusList: Array<HealthModel>;
  applicationStatusRecord:HealthModel;
  updateSubscription: Subscription;
  errorMessage: string;
  domainOwner:string;
   //to indicate processsing started or not started
   processing: boolean;
   filteredArray:Array<HealthModel>;
   sortedList:Array<HealthModel>;
   accessToken:string;
   appNameFilter:string="";
   statusStartTime:Date;
   statusEndTime:Date;
   disableAppFilter:boolean=false;
   disableStatusFilter:boolean=false;
   pageIndex:number = 0;
   pageSize:number= 50;
   
 public filterOptions: Array<string> = ["HEALTHY", "UNKNOWN","UNHEALTHY"];
 public selectedFilters: Array<string>;
 public appFilter:string;
 
 
 constructor(@Inject(HealthService) private _service: HealthService,@Inject(Router) private router: Router
 ,@Inject(Http) private http: Http,private route: ActivatedRoute
 ,private oktaAuth: OktaAuthService) { }
 // Create default client
 updateSelectedFilters(event) {
   
   this.selectedFilters = event;
  
  if(this.selectedFilters.length === 0){
    this.disableAppFilter=false;
  }
  else{
    this.disableAppFilter=true;
  }
   this.filteredArray = this.applicationStatusList.filter(e =>(this.selectedFilters.includes( e.derivedStatus) ) || this.selectedFilters.length === 0 );
    this.sortedList=this.filteredArray;
 }


 filterAppName(event:any){
   this.appFilter = event.target.value;
   
   if(this.appFilter.trim().length >0){
     
   this.disableStatusFilter=true;
   this.filteredArray = this.applicationStatusList.filter(e =>e.serviceName.toLocaleLowerCase().includes(this.appFilter.trim().toLocaleLowerCase()) || this.appFilter.length === 0 );
    this.sortedList=this.filteredArray; 
  }
   else{
    
    this.disableStatusFilter=false;
    this.filteredArray = this.applicationStatusList.filter(e =>e.serviceName.toLocaleLowerCase().includes(this.appFilter.trim().toLocaleLowerCase()) || this.appFilter.length === 0 );
    this.sortedList=this.filteredArray; 
   }
 }

 sortList(sort: Sort) {
  const data = this.filteredArray.slice();
  if (!sort.active || sort.direction === '') {
     this.filteredArray = data;
     return;
  }
  this.sortedList = data.sort((a, b) => {
     const isAsc = sort.direction === 'asc';
     switch (sort.active) {
        case 'name': return this.compare(a.serviceName, b.serviceName, isAsc);
        case 'app-status': return this.compare(a.appStatus, b.appStatus, isAsc);
        case 'infra-status': return this.compare(a.infraStatus, b.infraStatus, isAsc);
        default: return 0;
     } 
  });
}

 async ngOnInit() {
  const authenticated = await this.oktaAuth.isAuthenticated();
  if (authenticated) {
    this.accessToken = await this.oktaAuth.getAccessToken();
    console.log(this.accessToken);
    
  }
   
  this.domainOwner=this.route.snapshot.paramMap.get("owner");
   this.fetchApplicationStatus();
   this.filteredArray=this.applicationStatusList;
   this.sortedList = this.filteredArray;
   this.updateSubscription = interval(900000).subscribe(
     (val) => { this.updateStats()}
   );

 }
 ngOnDestroy() {
   this.updateSubscription.unsubscribe();
}

private updateStats() {
   
   this.errorMessage="";
   this.fetchApplicationStatus();
}
public roundTimeQuarterHour(time) {
  var timeToReturn = new Date(time);

  timeToReturn.setMilliseconds(Math.round(timeToReturn.getMilliseconds() / 1000) * 1000);
  timeToReturn.setSeconds(Math.round(timeToReturn.getSeconds() / 60) * 60);
  timeToReturn.setMinutes(Math.floor(timeToReturn.getMinutes() / 15) * 15);
  console.log("timeToReturn:"+timeToReturn);
  return timeToReturn;
}
 
onPageFired(event){
  this.pageIndex = event.pageIndex;
  this.pageSize = event.pageSize;

}
 fetchApplicationStatus():void{
   this.applicationStatusList = new Array<HealthModel>();
   this.processing = true;
  this.statusEndTime =new Date();
   this.statusStartTime= new Date();
   
   this.statusStartTime=this.roundTimeQuarterHour(this.statusStartTime.setMinutes((this.statusStartTime.getMinutes()-15),0,0));
   this.statusEndTime=this.roundTimeQuarterHour(this.statusEndTime.setMinutes((this.statusEndTime.getMinutes()),0,0));
  
     this._service.fetchLeaders("https://healthdashboard.sre.nikecloud.com/status?domain="+this.domainOwner+"&timestamp="+Date.now(),(err:any,resp:Response)=>{
     //this._service.fetchLeaders("http://localhost:8089/status?domain="+this.domainOwner+"&timestamp="+Date.now(),(err:any,resp:Response)=>{

     let body:any
      if(err){
      console.log(err)
      this.errorMessage=err
      }
      else{
       body=resp.json();
        var items=body;
        console.log(body);
        for (let record of items){
          console.log("this.calculateDerivedStatus(record.appStatus,record.infraStatus)------>"+this.calculateDerivedStatus(record.appStatus,record.infraStatus))
          if(record!=null){
            this.applicationStatusRecord =new HealthModel(record.healthStatusId.appName,this.calculateDerivedStatus(record.appStatus,record.infraStatus),record.statusMessage,record.appStatus,record.appStatusMessage,record.infraStatus,record.infraStatusMessage);
            this.applicationStatusList.push(this.applicationStatusRecord);
          }
        }
        this.applicationStatusList;
      }
 
      this.applicationStatusList.sort(function(obj1,obj2){
        if(obj1.serviceName > obj2.serviceName)
        {return 0}
        return -1;
      })
      console.log("this.applicationStatusList.length"+this.applicationStatusList.length);
      this.processing = false;
      
     },this.accessToken)
 }
 
calculateDerivedStatus(appStatus:string,infraStatus:string):string{
   let derivedStatus:string;

  if(appStatus == 'HEALTHY' && infraStatus == 'HEALTHY')
    return "HEALTHY";
  
  else if(appStatus == 'UNHEALTHY' && infraStatus == 'UNHEALTHY')
     return "UNHEALTHY";
  
  else if(appStatus == 'UNKNOWN' && infraStatus == 'UNKNOWN')
     return "UNKNOWN"; 
   
     else if(appStatus == 'UNKNOWN' || infraStatus == 'UNKNOWN')
     return "UNKNOWN";    
     
     else if(appStatus == 'UNHEALTHY' || infraStatus == 'UNHEALTHY')
     return "UNHEALTHY";  
  }

   compare(a:  string, b: string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
 }

}
