import { Component, OnInit } from '@angular/core';

import {Inject} from '@angular/core';
import {HealthService } from '../health.service';

import {LeadersModel} from '../models/leaders.model';
import {LeaderOwnerModel} from  '../models/leaderowner.model';

import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

import { Http } from '@angular/http';
import { OktaAuthService } from '@okta/okta-angular';

@Component({
  selector: 'app-leaders-component',
  templateUrl: './leaders-component.component.html',
  styleUrls: ['./leaders-component.component.css']
})
export class LeadersComponentComponent  implements OnInit {

  domainsArrayList: Array<LeadersModel>;
  domainRecord:LeadersModel;
   ownerRecord:LeaderOwnerModel;
   ownersArrayList:Array<LeaderOwnerModel>;
  updateSubscription: Subscription;
  errorMessage: string;
   //to indicate processsing started or not started
   processing: boolean;
   filteredArray:Array<LeadersModel>;
   accessToken:string;
 


 
 constructor(@Inject(HealthService) private _service: HealthService,@Inject(Router) private router: Router
 ,@Inject(Http) private http: Http,private oktaAuth: OktaAuthService) { }
 

 async ngOnInit() {

  const authenticated = await this.oktaAuth.isAuthenticated();
  if (authenticated) {
    this.accessToken = await this.oktaAuth.getAccessToken();
    console.log(this.accessToken);
    
  }
   
   this.fetchApplicationStatus();
   

 }
 
 
 fetchApplicationStatus():void{
   this.domainsArrayList = new Array<LeadersModel>();
   this.processing = true;
     this._service.fetchLeaders("https://healthdashboard.sre.nikecloud.com/domains",(err:any,resp:Response)=>{
      //this._service.fetchLeaders("http://localhost:8089/domains",(err:any,resp:Response)=>{

     let body:any
      if(err){
      console.log(err)
      this.errorMessage=err
      }
      else{
       body=resp.json();
       var items=body;

        for (const [domain,domainOwners] of Object.entries(items)){
         
          console.log(domain,domainOwners)
          let ownerList:any;
          ownerList=domainOwners;
          this.ownersArrayList = new Array<LeaderOwnerModel>();
           for(const owner of ownerList )
           {
            console.log("data"+owner);
            this.ownerRecord = new LeaderOwnerModel(owner);
            this.ownersArrayList.push(this.ownerRecord);

           }
           console.log(this.ownersArrayList);
         
         this.domainRecord =new LeadersModel(domain,this.ownersArrayList);
         this.domainsArrayList.push(this.domainRecord);
        }
       
      }
      console.log("this.applicationStatusList.length"+this.domainsArrayList.length);
      this.processing = false;
      
     },this.accessToken)
 }
 
 

}
