import { Injectable ,Inject} from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';

@Injectable({
  providedIn: 'root'
})
export class ManagementService {
  
  constructor(@Inject(Http) private http: Http) { }

  /**
     * Generic error handler. 
     */
    private handleError (error: Response | any, callback: Function) {
      // In a real world app, we might use a remote logging infrastructure
      let errMsg: string;
      if (error instanceof Response) {
          try {
              const body = error.json() || '';//this will fail miserably if response isn't JSON
              console.log(body);
              const err = body.error || JSON.stringify(body);
              errMsg = `${err}`;
          } catch (e) {
              console.log("Failed to parse error response for " + error.toString());
              errMsg = error.statusText; // falling back to plain text error response
          }
      } else {
          console.log("got some other error")
          errMsg = error.message ? error.message : error.toString();
          console.log(errMsg);
      }
      callback(errMsg);
  }

  public doGet(endpoint:string,callback:Function){
      console.log("do get endpoint-->"+endpoint);
      
    var url=endpoint; 
    let headers= new Headers(
      {
      'Content-type':'application/json'
     });
    let options = new RequestOptions({headers:headers});
    this.http.get(url,options)
    .subscribe((res: Response)=>{
         callback(null,res);
    },
    (err: any) => this.handleError(err,callback));
  }


  public doGetJWTHeader(endpoint:string,callback:Function,authorizationHeader:string){
    console.log("do get endpoint-->"+endpoint);
    
  var url=endpoint; 
  let headers= new Headers(
    {
    'Content-type': 'application/json',
    'Authorization': 'Bearer '+authorizationHeader,
    'appId': 'healthdashboardv2'

   });
   console.log(headers);
  let options = new RequestOptions({headers:headers});
  this.http.get(url,options)
  .subscribe((res: Response)=>{
       callback(null,res);
  },
  (err: any) => this.handleError(err,callback));
}


  /**
     * Performs a POST operation
     */
    public doPost(endpoint: string, body: any, callback: Function) {
        
      var url =  endpoint;

         let headers = new Headers(
          { 
            'Content-type':'text/plain'
           
          });

          let options = new RequestOptions({ headers: headers });
          
          this.http.post(url, body, options)
              .subscribe((res: Response) => {
                  callback(null, res);
              },
              (err: any) => this.handleError(err,callback));
      
  }



}


