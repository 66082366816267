import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { OktaAuthService } from '@okta/okta-angular';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'health-dashboard';
 constructor(private mRouter:Router,private oktaAuth: OktaAuthService){
 console.log(mRouter.url);

 }

 async ngOnInit() {

  const authenticated = await this.oktaAuth.isAuthenticated();
  if (authenticated) {
    const oktaToken = await this.oktaAuth.getAccessToken();
    console.log(oktaToken)
    
  }
}

}
