import { Injectable } from '@angular/core';

export const environment = {
    production: false
};

@Injectable()
export class Environment {
    private hostName:string;
    private appId:string;
    private redirectURL:string;

   
    static getOktaConfig() {
        return {
            issuer: "https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7",
           clientId: "nike.reliability.servicestatus",
           redirectUri: 'https://health-dashboard-dev.sre.nikecloud.com/implicit/callback',
           scope: 'profile openid email'
        }
    }
}

    
