export class HealthModel{
    public serviceName:string;
    public derivedStatus:string;
    public reason:string;
    public appStatus:string;
    public appReason:string;
    public infraStatus:string;
    public infraReason:string;
    
    constructor(serviceName:string,derivedStatus:string,reason:string,appStatus:string,appReason:string,infraStatus:string,infraReason:string){
        this.serviceName=serviceName;
        this.derivedStatus=derivedStatus;
        this.reason=reason;
        this.appStatus=appStatus;
        this.appReason=appReason;
        this.infraStatus=infraStatus;
        this.infraReason=infraReason;
        
    }

}