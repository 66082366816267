import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HealthService } from './health.service';
import { ManagementService } from './management.service';
import {HttpModule} from '@angular/http';
import { Environment } from 'src/environments/environment';
import {MatSortModule} from '@angular/material/sort';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatPaginatorModule} from '@angular/material'
import {
 
  OktaAuthModule,
} from '@okta/okta-angular';

import {SuiModule} from 'ng2-semantic-ui';
import { DashCardComponent } from './dash-card/dash-card.component';

import { HttpClientModule } from '@angular/common/http';

import { LeadersComponentComponent } from './leaders-component/leaders-component.component';
import { OwnerServiceHealthComponent } from './owner-service-health/owner-service-health.component';

import { FormsModule } from '@angular/forms';




@NgModule({

  declarations: [
    AppComponent,
    DashCardComponent,
    LeadersComponentComponent,
    OwnerServiceHealthComponent,
 
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpModule,
    SuiModule,
    OktaAuthModule.initAuth(Environment.getOktaConfig()),
    HttpClientModule,
    FormsModule,
    MatSortModule,
    MatPaginatorModule,
    BrowserAnimationsModule
  ],
  providers: [HealthService,
    ManagementService,
    Environment,
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }
