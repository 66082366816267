import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'app-dash-card',
  templateUrl: './dash-card.component.html',
  styleUrls: ['./dash-card.component.css']
})
export class DashCardComponent implements OnInit {

  constructor() { }
  @Input() title:         string;
  @Input() primaryIcon:   string;
  @Input() description?:  string;
  @Input() rightContent?:  boolean;
  @Input() backLink?:      string;

  ngOnInit() {
  }

}
