import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {OktaCallbackComponent,OktaLoginRedirectComponent,OktaAuthGuard} from '@okta/okta-angular/';

import { LeadersComponentComponent } from './leaders-component/leaders-component.component';
import { OwnerServiceHealthComponent } from './owner-service-health/owner-service-health.component';


const routes: Routes = [
  {path:'',pathMatch: 'full',redirectTo: 'leaders'},
  {path:'leaders',component:LeadersComponentComponent,canActivate: [OktaAuthGuard]},
  {path:'health/:owner',component:OwnerServiceHealthComponent, canActivate: [OktaAuthGuard]},

  
  { path: 'implicit/callback', component: OktaCallbackComponent },
  { path: 'login', component: OktaLoginRedirectComponent },
  { path: '**', redirectTo: 'login' },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]

})
export class AppRoutingModule { }
